import React from "react"
import { graphql, PageProps } from "gatsby"
import Page from "@app/components/Giftcard/Giftcard"

export type Props = PageProps<GatsbyTypes.PageGiftcardQuery, GatsbyTypes.PageGiftcardQueryVariables>

export const query = graphql`
  query PageGiftcard {
    page: sanityPageGiftcard {
      title
      url
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      content: _rawContent(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Page {...props} {...data} />
export default Component
