import React from "react"
import { useGiftcard } from "@app/hooks/useGiftcard"

import type { Location } from "@root/types/global"

type Props = {
  location: Location
}

const GiftcardItem: React.FC<Props> = ({ location }) => {
  const { applied, card, errors, loading, show, handleApply, handleShow } = useGiftcard(location)

  return (
    <figure>
      <figcaption>
        {card ? (
          <>
            {card?.customer && <p>Issued to: {card?.customer?.name || card?.customer?.email}</p>}
            {card?.expires && <p>Expires: {show ? card?.expires : card?.codeHidden}</p>}
            <p>
              Balance: {card?.balance?.remaining} / {card?.balance?.initial}
            </p>
            <p>
              <strong>Code: {show ? card?.code?.full : card?.code?.partial}</strong>
            </p>
            <button onClick={handleShow}>{show ? "Hide code" : "Show code"}</button>
            {card?.enabled && (
              <button disabled={applied || loading} onClick={handleApply}>
                {applied ? "Applied to cart" : "Apply to cart"}
              </button>
            )}
          </>
        ) : (
          <p>No gift card to show</p>
        )}

        {errors?.map(item => <p key={item?.toString()}>{item?.toString()}</p>)}
      </figcaption>
    </figure>
  )
}

export default React.memo(GiftcardItem)
