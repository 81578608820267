import React from "react"
import { Container, Box, Heading } from "@chakra-ui/react"
import { useContent } from "@app/hooks/useContent"
import GiftcardItem from "./GiftcardItem"

import type { Props } from "@app/pages/giftcard"
import type { PageProps } from "@root/types/global"

const Giftcard: React.FC<PageProps<Props>> = ({ location, page }) => {
  const content = useContent(page)
  return (
    <Container>
      <Heading as="h1">{page?.title}</Heading>
      <Box>{content}</Box>
      <GiftcardItem location={location} />
    </Container>
  )
}

export default Giftcard
